import activity from './m-arrival.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Arrival',
  icon: 'mdi-tag-check',
  description: 'Check-in upon arrival',
  status: true,
  styling: {
    borderColor: '#6eba80' // color of choice for your activity
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-arrival',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        arrivalLink: '',
        arrivalStatus: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
